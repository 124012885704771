import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import theme from "../../theme";

export default function ChangeLanguage(props) {
  let { handleLanguageChange, title } = props;

  const countries = [
    { code: "GB", language: "en" },
    { code: "DE", language: "de" },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const update_Language = (e) => {
    handleLanguageChange(e);
    handleClose();
  };

  const ArrowUp = () => {
    return <ChevronUpIcon className="h-6 w-6 text-black" aria-hidden="true" />;
  };
  const ArrowDown = () => {
    return (
      <ChevronDownIcon className="h-6 w-6 text-black" aria-hidden="true" />
    );
  };

  return (
    <div className="h-[35px] w-[110px]">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        sx={{
          display: "flex",
          width: "110px",
          height: "35px",
          alignItems: "center",
          justifyContent: "center",
          borderColor: theme.colors.primary,
        }}
      >
        <img
          src={`https://flagcdn.com/w20/${title?.code?.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${title?.code?.toLowerCase()}.png 2x`}
          className="w-[30px] h-[18px] bg-white"
          alt=""
        />
        <p className="sm2:pl-2 pl-1 font-bold text-black capitalize">
          {title?.language?.toUpperCase()}
        </p>
        {open ? <ArrowUp /> : <ArrowDown />}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            padding: 0,
            boxShadow: "none",
          },
        }}
        sx={{
          borderRadius: 0,
          top: "7px",
          boxShadow: "none",
          "& .MuiPaper-root": {
            boxShadow: "0 0px 12px #049d3e69",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="w-32 flex flex-col gap-y-2 items-start rounded-md bg-white leading-6 p-1 shadow-[0_0px_20px_#3c7fed90]">
          {countries.map((item, index) => (
            <div
              className={`flex w-full justify-start gap-x-3 items-center pl-2 pr-1 py-1 ${
                item?.language === title?.language
                  ? " hover:cursor-default"
                  : "hover:bg-slate-200 hover:cursor-pointer"
              } `}
              onClick={() => {
                update_Language(item);
              }}
              key={index}
            >
              <img
                className="w-[40px] h-[20px] min-w-[40px] max-w-[40px]"
                src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                alt="flag"
              />
              <div
                key={item.language}
                className={` ${
                  item?.language === title?.language
                    ? "text-primary"
                    : "text-black"
                } text-[15px] font-sans font-semibold `}
              >
                {item.language.toUpperCase()}
              </div>
              {item?.language === title?.language ? (
                <DoneRoundedIcon
                  fontSize="small"
                  sx={{ marginBottom: "5px", color: "green" }}
                />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </Menu>
    </div>
  );
}
