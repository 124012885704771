import React from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { Img, HeadingText } from "@components";
import golfLogo from "@assets/images/golfLogo.png";
import newsImg from "@assets/images/news1.png";
import theme from "../../theme";

const NewsCard = ({ item, onLike, onDislike }) => {
  const { t } = useTranslation("common");

  const CustomLink = ({ href, children }) => (
    <a
      href={href}
      target="_blank"
      rel="golfclix"
      style={{ color: theme.colors.blue, textDecoration: "underline" }}
    >
      {children}
    </a>
  );

  const CustomParagraph = ({ children }) => (
    <p style={{ fontSize: "16px", lineHeight: "1.5" }}>{children}</p>
  );

  return (
    <Root
      className={
        item?.is_advertisement
          ? "border-2 border-[black]"
          : "border-8 border-[#f3f3f3]"
      }
    >
      <HeaderView>
        <div className="w-auto h-auto flex flex-row items-start justify-start gap-x-4">
          <div className="w-[55px] min-w-[55px] h-[55px] min-h-[55px]">
            <Img src={item?.publisher?.image || golfLogo}></Img>
          </div>
          <div className="h-full w-auto flex flex-col items-start justify-between gap-y-1">
            <HeadingText className="line-clamp-2">
              {item?.publisher?.name || "GolfClix"}
            </HeadingText>
            <p className="text-[12px] text-textShadow">
              {moment(item?.publish_date_time).fromNow()}
            </p>
          </div>
        </div>

        <IconButton
          onClick={() => {}}
          sx={{
            ":hover": {
              color: theme.colors.primary,
            },
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </HeaderView>

      {item?.media && (
        <ImgView>
          <div
            className={`w-full h-full relative ${
              item?.is_advertisement ? "brightness-50" : ""
            }`}
          >
            <Img src={item?.media || newsImg}></Img>
          </div>
          {item?.is_advertisement ? (
            <div className="w-[80px] h-[30px] bg-primary rounded-md flex items-center justify-center absolute top-6 left-6">
              <p className="text-white">{"Ads"}</p>
            </div>
          ) : (
            <></>
          )}
        </ImgView>
      )}

      <BodyView>
        <ReactMarkdown
          components={{
            a: CustomLink,
            p: CustomParagraph,
          }}
          remarkPlugins={[remarkGfm, remarkBreaks]}
          rehypePlugins={[rehypeRaw]}
          children={item?.description?.toString()}
        />
      </BodyView>

      {!item?.is_advertisement ? (
        <FooterView>
          <div className="w-auto flex flex-row items-center justify-start md:gap-x-5 gap-x-0">
            {item?.is_like ? (
              <IconButton
                onClick={onDislike}
                sx={{
                  ":hover": {
                    color: theme.colors.primary,
                  },
                }}
              >
                <ThumbUpIcon sx={{ color: theme.colors.primary }} />
              </IconButton>
            ) : (
              <IconButton
                onClick={onLike}
                sx={{
                  ":hover": {
                    color: theme.colors.primary,
                  },
                }}
              >
                <ThumbUpOutlinedIcon />
              </IconButton>
            )}
            <IconButton
              onClick={() => {}}
              sx={{
                ":hover": {
                  color: theme.colors.primary,
                },
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4170_1204)">
                  <path
                    d="M5.625 9.75C5.625 9.33578 5.96078 9 6.375 9H17.625C18.0392 9 18.375 9.33578 18.375 9.75C18.375 10.1642 18.0392 10.5 17.625 10.5H6.375C5.96078 10.5 5.625 10.1642 5.625 9.75Z"
                    fill="#676767"
                  />
                  <path
                    d="M6.375 13.5C5.96078 13.5 5.625 13.8358 5.625 14.25C5.625 14.6642 5.96078 15 6.375 15H10.875C11.2892 15 11.625 14.6642 11.625 14.25C11.625 13.8358 11.2892 13.5 10.875 13.5H6.375Z"
                    fill="#676767"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.75 12C0.75 5.78681 5.78681 0.75 12 0.75C18.2132 0.75 23.25 5.78681 23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C10.0112 23.25 8.1411 22.7333 6.5187 21.8265L2.69177 23.2056C2.43119 23.2995 2.14007 23.2421 1.93459 23.0564C1.72911 22.8706 1.64271 22.5868 1.7099 22.3181L2.70468 18.339C1.4714 16.5337 0.75 14.3501 0.75 12ZM12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 14.1491 2.94443 16.1337 4.12133 17.7448C4.25257 17.9245 4.29728 18.1532 4.24331 18.3691L3.50616 21.3177L6.33862 20.297C6.55091 20.2205 6.78634 20.2436 6.97965 20.36C8.44553 21.2423 10.1623 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25Z"
                    fill="#676767"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4170_1204">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </IconButton>
          </div>
          <div className="w-auto flex flex-row items-center justify-start md:gap-x-7 gap-x-2">
            <p>
              <span className=" font-semibold">{item?.total_likes}</span>
              {t("news.like")}
            </p>
            <p>
              <span className=" font-semibold">{item?.total_comments}</span>
              {t("news.comment")}
            </p>
          </div>
        </FooterView>
      ) : (
        <></>
      )}
    </Root>
  );
};

const Root = tw.div`
h-auto 
xl:w-[830px] w-full 
md:p-6 p-3 
flex flex-col justify-between 
gap-y-5 
border-8  
rounded-md

`;

const HeaderView = tw.div`
h-auto
min-h-[55px] 
w-full 
flex flex-row items-center justify-between
`;

const ImgView = tw.div`
w-full 
lg:h-[300px] md:h-[250px] sm2:h-[200px] h-[160px] 
rounded-md
relative
`;

const BodyView = tw.div`
w-full 
h-auto 
flex flex-col 
gap-y-1
`;

const FooterView = tw.div`
w-full 
h-auto 
flex flex-row 
items-center 
justify-between justify-self-end
`;

export default NewsCard;
