import { createAsyncThunk } from "@reduxjs/toolkit";
import { MAKE_PAYMENT } from "./types";
import { makePaymentApi } from "../../services/payment";

export const makePaymentDetails = createAsyncThunk(
  MAKE_PAYMENT,
  async (data) => {
    try {
      const response = await makePaymentApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);
