import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "@pages/errorPage";
import { Header } from "@components";
import { Storage } from "@utils";
import { change_Language } from "@redux/settings/slice";
import { setUserDetails } from "@redux/authentication/slice";
import routes from "./routes";

const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation("common");
  const { current_Language } = useSelector((store) => store.settingsReducer);

  useEffect(() => {
    checkUserDetails();
  }, []);

  useEffect(() => {
    checkLanguage();
  }, [current_Language]);

  const checkUserDetails = useCallback(async () => {
    let selectUser = await Storage.get(Storage.USER_DATA);
    if (selectUser) {
      dispatch(setUserDetails(JSON.parse(selectUser)));
    } else {
    }
  }, []);

  const checkLanguage = useCallback(async () => {
    let selectLanguage = await Storage.get(Storage.LANGUAGE);
    if (selectLanguage) {
      i18n.changeLanguage(selectLanguage);
      dispatch(change_Language(selectLanguage));
    } else {
      i18n.changeLanguage("en");
      dispatch(change_Language("en"));
    }
  }, []);

  return (
    <div
      style={{
        width: "full",
        height: "full",
        maxWidth: "1600px",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        margin: "auto",
        paddingBottom: "10px",
      }}
    >
      <Routes>
        {routes.map((item, index) => (
          <Route
            key={"" + index}
            path={item.path}
            exact={item.exact}
            name={item.name}
            element={
              <>
                <Header />
                {item.element}
              </>
            }
          />
        ))}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default App;
