import { TOURNAMENTS_LIST, OFFICIAL_TOURNAMENTS_APPLY } from "@utils/config";
import { httpsAPICall } from "../apis";

export const tournamentsApi = (data) => {
  const response = httpsAPICall(
    `${TOURNAMENTS_LIST}?club_id=${data.clubId}&start_date=${data.startDate}&end_date=${data.endDate}&page_size=${data.pageSize}&page_no=${data.pageNo}&time_zone=${data.timeZone}&type=${data.type}`,
    // `${TOURNAMENTS_LIST}?club_id=${data.clubId}&start_date=2020-01-01&end_date=2024-10-20&page_size=${data.pageSize}&page_no=${data.pageNo}&time_zone=${data.timeZone}&type=${data.type}`,
    data,
    "get"
  );
  return response;
};

export const officialTournamentApplyApi = (data) => {
  const response = httpsAPICall(
    `${OFFICIAL_TOURNAMENTS_APPLY}?tournament_id=${data}`,
    data,
    "put"
  );
  return response;
};

export const tournamentDetailApi = (data) => {
  const response = httpsAPICall(`${TOURNAMENTS_LIST}/${data}`, data, "get");
  return response;
};
