import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_Language: "en",
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    change_Language: (state, action) => {
      state.current_Language = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { change_Language } = slice.actions;

export default slice.reducer;
