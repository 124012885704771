import { createSlice } from "@reduxjs/toolkit";
import { getGolfAcitivityData, getUserFeedData } from "./actions";

const initialState = {
  golfAcitivity: [],
  userFeed: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGolfAcitivityData.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.golfAcitivity = action?.payload?.data?.data;
      }
    });
    builder.addCase(getUserFeedData.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.userFeed = action?.payload?.data?.data;
      }
    });
  },
});

export default slice.reducer;
