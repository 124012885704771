import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Text } from "@components";
import theme from "../../theme";

const MuiInput = (props) => {
  let { label, value, change_Value } = props;

  return (
    <Box
      component="form"
      sx={{ "& > :not(style)": { width: "100%" } }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="standard-basic"
        label={label}
        variant="standard"
        value={value}
        onChange={(event) => {
          change_Value(event.target.value);
        }}
        disabled
        slotProps={{
          inputLabel: {
            sx: {
              fontSize: "18px",
              "-webkit-text-fill-color": theme.colors.textShadow,
            },
          },
          input: {
            sx: {
              height: "50px",
              fontSize: "15px",
              "&.Mui-disabled:before": {
                borderBottomStyle: "solid",
                borderBottomColor: theme.colors.textShadow,
              },
              "&.Mui-disabled .MuiInput-input": {
                "-webkit-text-fill-color": theme.colors.black,
              },
            },
          },
        }}
      />
    </Box>
  );
};

const CustomInput = (props) => {
  let {
    showPassword,
    label,
    placeholder,
    value,
    change_Value,
    handleShowPassword,
    error,
    errorMessage,
    onKeyDown,
  } = props;

  return (
    <form noValidate autoComplete="off">
      <FormControl sx={{ width: "100%" }}>
        <Text className="pb-2 font-semibold text-black">{label}</Text>
        <OutlinedInput
          type={label === "Password" && !showPassword ? "password" : "text"}
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            change_Value(event.target.value);
          }}
          error={error}
          endAdornment={
            label === "Password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            )
          }
          onKeyDown={onKeyDown}
        />
        <FormHelperText sx={{ color: theme.colors.red }}>
          {errorMessage || ""}
        </FormHelperText>
      </FormControl>
    </form>
  );
};

export { MuiInput, CustomInput };
