import React from "react";
import Avatar from "@mui/material/Avatar";
import clubImg from "@assets/images/img1.png";

const Img = (props) => {
  return (
    <>
      <img
        src={props?.src ? props?.src : clubImg}
        alt=""
        className={`w-full h-full object-cover ${
          props.rounded ? "rounded-full" : "rounded-md"
        }`}
      />
    </>
  );
};

function Avatars(props) {
  return (
    <Avatar
      alt={props?.name}
      src={props?.src ? props?.src : clubImg}
      sx={{ width: 48, height: 48 }}
    />
  );
}

export { Img, Avatars };
