import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import {
  Authentication,
  ChangeLanguage,
  LoginModal,
  TostMessage,
} from "@components";
import { Storage, isEmail } from "@utils";
import { change_Language } from "@redux/settings/slice";
import { loginUser, registerUser } from "@redux/authentication/actions";
import { logoutUser } from "@redux/authentication/slice";
import logoIcon from "@assets/images/logo.png";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation("common");
  const { userData } = useSelector((store) => store.authReducer);

  const [language, setLanguage] = useState({
    code: "GB",
    language: "en",
  });

  const [state, setState] = useState({
    loginModal: false,
    isRegister: false,
    name: "",
    surname: "",
    email: "",
    password: "",
    nameError: false,
    surnameError: false,
    emailError: false,
    validEmailError: false,
    passwordError: false,
    showPassword: false,
    btnLoading: false,
    alertMessage: false,
    alertMessageTxt: null,
  });

  useEffect(() => {
    checkLanguage();
  }, []);

  async function checkLanguage() {
    try {
      let selectLanguage = await Storage.get(Storage.LANGUAGE);
      if (selectLanguage === "de") {
        setLanguage({
          code: "DE",
          language: "de",
        });
      } else {
        setLanguage({
          code: "GB",
          language: "en",
        });
      }
    } catch (error) {}
  }

  const handleLanguageChange = (props) => {
    setLanguage(props);
    i18n.changeLanguage(props.language);
    Storage.save(Storage.LANGUAGE, props.language);
    dispatch(change_Language(props.language));
  };

  const goToHome = () => {
    navigate("/");
  };

  const open_LoginModal = () => {
    setState((prev) => ({ ...prev, loginModal: true }));
  };

  const close_LoginModal = () => {
    setState((prev) => ({
      ...prev,
      loginModal: false,
      name: "",
      surname: "",
      email: "",
      password: "",
      nameError: false,
      surnameError: false,
      emailError: false,
      validEmailError: false,
      passwordError: false,
    }));
  };

  const switchModal = () => {
    setState((prev) => ({
      ...prev,
      isRegister: !prev.isRegister,
      name: "",
      surname: "",
      email: "",
      password: "",
      nameError: false,
      surnameError: false,
      emailError: false,
      validEmailError: false,
      passwordError: false,
    }));
  };

  const change_Name = (e) => {
    setState((prev) => ({
      ...prev,
      name: e,
      nameError: false,
    }));
  };

  const change_Surname = (e) => {
    setState((prev) => ({
      ...prev,
      surname: e,
      surnameError: false,
    }));
  };

  const change_Email = (e) => {
    setState((prev) => ({
      ...prev,
      email: e,
      emailError: false,
      validEmailError: false,
    }));
  };

  const change_Password = (e) => {
    setState((prev) => ({
      ...prev,
      password: e,
      passwordError: false,
    }));
  };

  const handle_ShowPassword = () => {
    setState((prev) => ({
      ...prev,
      showPassword: !prev.showPassword,
    }));
  };

  const onLogin = useCallback(
    async (e) => {
      e.preventDefault();

      if (state.isRegister) {
        const { name, surname, email, password } = state;
        if (!name || !surname || !email || !password || !isEmail(email)) {
          setState((prev) => ({
            ...prev,
            nameError: !name,
            surnameError: !surname,
            emailError: !email,
            passwordError: !password,
            validEmailError: !isEmail(email),
          }));
          return;
        }

        const apiData = {
          firstname: name,
          lastname: surname,
          email,
          password,
          device_token: 123456,
          device_type: "web",
          device_id: 1234,
          login_type: "email",
        };
        setState((prev) => ({
          ...prev,
          btnLoading: true,
          alertMessage: false,
        }));

        try {
          const response = await dispatch(registerUser(apiData));

          const resData = response?.payload?.data?.data;
          const status = response?.payload?.status;

          if (resData && status === 200) {
            setState((prev) => ({
              ...prev,
              btnLoading: false,
              alertMessage: true,
              alertMessageTxt: "Register successfully",
              name: "",
              surname: "",
              email: "",
              password: "",
              loginModal: false,
              isRegister: false,
            }));
          } else {
            const errorMessage =
              response?.payload?.response?.data?.message ||
              response?.payload?.message ||
              response?.payload?.data?.message ||
              "A generic error occurred on the register!";
            setState((prev) => ({
              ...prev,
              btnLoading: false,
              alertMessage: true,
              alertMessageTxt: errorMessage,
            }));
          }
        } catch (error) {
          setState((prev) => ({
            ...prev,
            btnLoading: false,
            alertMessage: true,
            alertMessageTxt: "An unexpected error occurred. Please try again.",
          }));
        }
      } else {
        const { email, password } = state;
        if (!email || !password || !isEmail(email)) {
          setState((prev) => ({
            ...prev,
            emailError: !email,
            passwordError: !password,
            validEmailError: !isEmail(email),
          }));
          return;
        }

        const apiData = {
          email,
          password,
          device_token: 123456,
          device_type: "web",
          device_id: 1234,
        };
        setState((prev) => ({
          ...prev,
          btnLoading: true,
          alertMessage: false,
        }));

        try {
          const response = await dispatch(loginUser(apiData));

          const resData = response?.payload?.data?.data;
          const status = response?.payload?.status;

          if (resData && status === 200) {
            setState((prev) => ({
              ...prev,
              btnLoading: false,
              alertMessage: true,
              alertMessageTxt: "Login successfully",
              email: "",
              password: "",
              loginModal: false,
            }));
          } else {
            const errorMessage =
              response?.payload?.response?.data?.message ||
              response?.payload?.message ||
              response?.payload?.data?.message ||
              "A generic error occurred on the login!";
            setState((prev) => ({
              ...prev,
              btnLoading: false,
              alertMessage: true,
              alertMessageTxt: errorMessage,
            }));
          }
        } catch (error) {
          setState((prev) => ({
            ...prev,
            btnLoading: false,
            alertMessage: true,
            alertMessageTxt: "An unexpected error occurred. Please try again.",
          }));
        }
      }
    },
    [state, dispatch]
  );

  const goToProfile = () => {
    setTimeout(() => {
      navigate("/profile");
    }, 100);
  };

  const onLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <Root>
      <Logo src={logoIcon} onClick={goToHome} />
      <Coponent>
        <Authentication
          onClick={open_LoginModal}
          userData={userData}
          userLogout={onLogout}
          userProfile={goToProfile}
        />
        <ChangeLanguage
          title={language}
          handleLanguageChange={handleLanguageChange}
        />
      </Coponent>
      <LoginModal
        openModal={userData === null ? true : state.loginModal}
        closeModal={close_LoginModal}
        changeName={change_Name}
        name={state.name}
        changeSurname={change_Surname}
        surname={state.surname}
        email={state.email}
        changeEmail={change_Email}
        password={state.password}
        changePassword={change_Password}
        showPassword={state.showPassword}
        handleShowPassword={handle_ShowPassword}
        btnLoading={state.btnLoading}
        nameError={state.nameError}
        surnameError={state.surnameError}
        emailError={state.emailError}
        validEmailError={state.validEmailError}
        passwordError={state.passwordError}
        login={onLogin}
        isRegister={state.isRegister}
        switchModal={switchModal}
      />
      <TostMessage
        open={state.alertMessage}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            alertMessage: false,
            alertMessageTxt: null,
          }))
        }
        title={state.alertMessageTxt}
        type={"info"}
      />
    </Root>
  );
}

const Root = tw.div`
bg-white 
md:h-[100px] h-auto 
w-[100%] 
flex flex-row md:items-center items-start justify-between 
xl3:px-40 xl2:px-36 xl1:px-28 xl:px-24 
lg2:px-20 lg:px-16
md2:px-12 md:px-10
sm2:px-6 sm:px-4
px-3
md:pt-0 pt-6 
`;

const Logo = tw.img`
w-[130px] 
h-[40px]
hover:cursor-pointer
`;

const Coponent = tw.div`
w-auto
h-auto 
flex md:flex-row flex-col-reverse md:items-center items-end md:justify-center justify-start 
md:self-center self-end
md:gap-4 gap-3 

`;
