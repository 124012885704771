import { createSlice } from "@reduxjs/toolkit";
import { makePaymentDetails } from "./actions";

const initialState = {
  paymentDetails: [],
};

const slice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(makePaymentDetails.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data) {
        state.paymentDetails = action?.payload?.data;
      }
    });
  },
});

export default slice.reducer;
