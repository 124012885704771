import { LOGIN_USER, REGISTER_USER } from "@utils/config";
import { httpsAPICall } from "../apis";

export const loginUserApi = (data) => {
  const response = httpsAPICall(LOGIN_USER, data, "post");
  return response;
};

export const registerUserAPi = (data) => {
  const response = httpsAPICall(REGISTER_USER, data, "post");
  return response;
};