import React from "react";
import { useTranslation } from "react-i18next";
import { MuiButton, User } from "@components";

const Authentication = (props) => {
  let { onClick, userData, userLogout, userProfile } = props;
  const { t } = useTranslation("common");

  const Login = () => (
    <div className={logIN}>
      <MuiButton onClick={onClick} height={"35px"}>
        {t("home.logIn")}
      </MuiButton>
    </div>
  );

  return (
    <React.Fragment>
      {userData && userData?.firstname ? (
        <User userData={userData} logOut={userLogout} profile={userProfile} />
      ) : (
        <Login />
      )}
    </React.Fragment>
  );
};

const logIN = `
w-[110px] 
h-[35px]
rounded-md 
`;

export default Authentication;
