import axios from "axios";
import { Storage } from "@utils";
import { API_URL } from "@utils/config";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers["X-Gravitee-Api-Key"] =
  "294acbc5-40f2-4b5e-9655-88b9cd0b2857";
// "64218761-332f-400f-8082-4a11e1a4a4a2"
axios.defaults.headers.common.Accept = "application/json";
axios.defaults["timeout"] = 1000000;

const urlBuilder = (path) => {
  return `${API_URL}${path}`;
};

const getToken = async () => {
  let userToken = await Storage.get(Storage.USER_TOKEN);
  let parseData = JSON.parse(userToken);
  if (parseData) {
    return parseData;
  }
  return null;
};

export const httpsAPICall = async (route, data, method = "get") => {
  const token = await getToken();

  if (token) {
    axios.defaults.headers.common = {
      token: `${token}`,
    };
  }
  return await axios[method](urlBuilder(route), data)
    .then((res) => {
      if (res) {
        return res;
      }
    })
    .catch((err) => {
      return err.response;
    });
};
