import React from "react";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";

const mui_Theme = createTheme({
  palette: {
    warning: {
      main: theme.colors.primary,
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <ThemeProvider theme={mui_Theme}>
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        color="warning"
        {...props}
      />
    </ThemeProvider>
  );
});

function TostMessage(props) {
  const position = {
    vertical: "top",
    horizontal: "right",
  };

  const { vertical, horizontal } = position;

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={props?.open}
      onClose={props?.onClose}
      TransitionComponent={(e) => <Slide {...e} direction="left" />}
      autoHideDuration={10000}
    >
      <Alert
        onClose={props?.onClose}
        severity={props?.type}
        sx={{ width: "100%" }}
      >
        {props?.title}
      </Alert>
    </Snackbar>
  );
}

export default TostMessage;
