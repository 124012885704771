import { createSlice } from "@reduxjs/toolkit";
import {
  getNewsFeedData,
  updateNewsFeedData,
  NewsFeedLikes,
  NewsFeedDislikes,
} from "./actions";

const initialState = {
  newsFeed: [],
  newsPaginationNo: 1,
  likeNewsFeed: null,
  dislikeNewsFeed: null,
};

const slice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNewsPaginationNo: (state, action) => {
      state.newsPaginationNo = action.payload;
    },
    editNewsFeed: (state, action) => {
      state.newsFeed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewsFeedData.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.newsFeed = action?.payload?.data?.data;
      }
    });
    builder.addCase(updateNewsFeedData.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.newsFeed = state.newsFeed.concat(action?.payload?.data?.data);
      }
    });
    builder.addCase(NewsFeedLikes.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.likeNewsFeed = action?.payload?.data?.data;
      }
    });
    builder.addCase(NewsFeedDislikes.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.dislikeNewsFeed = action?.payload?.data?.data;
      }
    });
  },
});

export const { setNewsPaginationNo, editNewsFeed } = slice.actions;
export default slice.reducer;
