export const USER_DATA = "UserData";
export const USER_TOKEN = "UserToken";
export const AUTHENTICATION = "authentication";
export const LANGUAGE = "Language";

export const get = async (key) => {
  try {
    return await localStorage.getItem(key);
  } catch (e) {}
};

export const save = async (key, value) => {
  try {
    await localStorage.setItem(key, value);
  } catch (e) {}
};

export const remove = async (key) => {
  try {
    await localStorage.removeItem(key);
  } catch (e) {}
};
