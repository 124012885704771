import React from "react";
import tw from "tailwind-styled-components";
import CircularProgress from "@mui/material/CircularProgress";

const AppLoader = () => {
  return (
    <React.Fragment>
      <Container>
        <CircularProgress color="success" />
      </Container>
    </React.Fragment>
  );
};

const Container = tw.div`
w-full 
h-full 
flex 
justify-center 
items-center
z-[110]
p-10
`;

export default AppLoader;
