import React from "react";
import tw from "tailwind-styled-components";
import Pagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Text } from "@components";
import theme from "../../theme";

const Paginations = (props) => {
  const {
    totalPage,
    tournamentPaginationNo,
    changePage,
    pageSizeMenu,
    isOpenPageSizeMenu,
    handlePageSizeMenu,
    closePageSizeMenu,
    pageSize,
    handlePageSize,
  } = props;

  const muiTheme = createTheme({
    palette: {
      secondary: {
        main: theme.colors.primary,
      },
    },
  });

  const handlePage = (e) => {
    handlePageSize(e);
    closePageSizeMenu();
  };

  return (
    <div className="w-full h-auto pt-7 flex justify-between items-center">
      <div className="w-auto h-auto flex flex-row items-center justify-start gap-x-2">
        <Button
          variant="outlined"
          onClick={handlePageSizeMenu}
          sx={{
            borderColor: theme.colors.primary,
            color: theme.colors.black,
            textTransform: "capitalize",
          }}
        >
          <Text className="pr-2 font-semibold">{pageSize}</Text>
          <ArrowDropDownIcon />
        </Button>

        <Menu
          id="basic-menu"
          aria-controls={isOpenPageSizeMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isOpenPageSizeMenu ? "true" : undefined}
          anchorEl={pageSizeMenu}
          open={isOpenPageSizeMenu}
          onClose={closePageSizeMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              padding: 0,
              boxShadow: "none",
            },
          }}
          sx={{
            borderRadius: 0,
            top: "-5px",
            boxShadow: "none",
            "& .MuiPaper-root": {
              boxShadow: "0 0px 12px #049d3e69",
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <div className={pageSizeView}>
            <Btn onClick={() => handlePage(10)}>
              <Text className="font-semibold">{10}</Text>
            </Btn>
            <Btn onClick={() => handlePage(20)}>
              <Text className="font-semibold">{20}</Text>
            </Btn>
            <Btn onClick={() => handlePage(50)}>
              <Text className="font-semibold">{50}</Text>
            </Btn>
            <Btn onClick={() => handlePage(100)}>
              <Text className="font-semibold">{100}</Text>
            </Btn>
          </div>
        </Menu>
        <div>
          <Text>{"Entries per page"}</Text>
        </div>
      </div>

      <ThemeProvider theme={muiTheme}>
        <Pagination
          count={totalPage}
          defaultPage={1}
          page={tournamentPaginationNo}
          onChange={changePage}
          color="secondary"
        />
      </ThemeProvider>
    </div>
  );
};

const pageSizeView = `
w-[80px] 
h-auto 
flex flex-col 
gap-y-[2px]
items-center justify-center 
rounded-md 
bg-white 
leading-6 
px-1 
py-2
shadow-[0_0px_20px_#3c7fed90]
`;

const Btn = tw.button`
py-1 
w-full 
h-auto 
text-start
hover:cursor-pointer hover:bg-slate-200
pl-4
`;

export default Paginations;
