import React, { lazy } from "react";

const Home = lazy(() => import("@pages/home"));
const TournamentDetail = lazy(() => import("@pages/tournamentDetails"));
const CheckoutPage = lazy(() => import("@pages/checkout"));
const Profile = lazy(() => import("@pages/profile"));

const routes = [
  { exact: true, isPublic: true, path: "/", name: "home", element: <Home /> },
  // { exact: true, isPublic: true, path: "/:club-id", name: "home", element: <Home /> },
  {
    exact: true,
    isPublic: true,
    path: "/tournament/:tournamentId",
    name: "tournamentDetail",
    element: <TournamentDetail />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/checkout",
    name: "checkout",
    element: <CheckoutPage />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/profile",
    name: "profile",
    element: <Profile />,
  },
];

export default routes;
