import React from "react";

const Text = (props) => {
  return (
    <p style={{ ...props.style }} className={`${props.className} `}>
      {props.children}
    </p>
  );
};
const HeadingText = (props) => {
  return (
    <div
      style={{ ...props.style }}
      className={`${props.className} font-bold text-[19px]`}
    >
      {props.children}
    </div>
  );
};

export { Text, HeadingText };
