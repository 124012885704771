import { createAsyncThunk } from "@reduxjs/toolkit";
import { GOLF_ACITIVITY, USER_FEED } from "./types";
import { golfAcitivityApi, userFeedApi } from "../../services/user";

export const getGolfAcitivityData = createAsyncThunk(
  GOLF_ACITIVITY,
  async (data) => {
    try {
      const response = await golfAcitivityApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getUserFeedData = createAsyncThunk(USER_FEED, async (data) => {
  try {
    const response = await userFeedApi(data);
    return response;
  } catch (err) {
    return err;
  }
});
