import React from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import Menu from "@mui/material/Menu";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { Avatars, Text } from "@components";
import userImg from "@assets/images/user.png";

const User = ({ userData, logOut, profile }) => {
  const { t } = useTranslation("common");

  const [openMenu, setOpenMenu] = React.useState(null);
  const open = Boolean(openMenu);

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleProfile = () => {
    handleClose();
    setTimeout(() => {
      profile();
    }, 400);
  };

  const handleLogout = () => {
    handleClose();
    logOut();
  };

  const ArrowUp = () => {
    return <ChevronUpIcon className="h-6 w-6 text-black" aria-hidden="true" />;
  };

  const ArrowDown = () => {
    return (
      <ChevronDownIcon className="h-6 w-6 text-black" aria-hidden="true" />
    );
  };

  return (
    <div className="h-auto w-auto">
      <div
        className="flex flex-row items-center justify-between gap-x-2 w-auto sm2:max-w-[210px] max-w-[160px] hover:cursor-pointer"
        onClick={handleClick}
      >
        <Avatars src={userData?.image || userImg} name={userData?.firstname} />
        <Text className="font-semibold capitalize truncate w-auto max-w-[130px]">
          {userData?.firstname}
        </Text>
        <div className="sm2:flex hidden">
          {open ? <ArrowUp /> : <ArrowDown />}
        </div>
      </div>

      <Menu
        id="basic-menu"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        anchorEl={openMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            padding: 0,
            boxShadow: "none",
          },
        }}
        sx={{
          borderRadius: 0,
          top: "7px",
          boxShadow: "none",
          "& .MuiPaper-root": {
            boxShadow: "0 0px 12px #049d3e69",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={user}>
          <Btn onClick={handleProfile}>
            <Text className="font-semibold">{t("home.profile")}</Text>
          </Btn>
          <Btn onClick={handleLogout}>
            <Text className="font-semibold">{t("home.logOut")}</Text>
          </Btn>
        </div>
      </Menu>
    </div>
  );
};

const user = `
w-[130px] 
h-auto 
flex flex-col 
gap-y-[2px]
items-center justify-center 
rounded-md 
bg-white 
leading-6 
px-1 
py-2
shadow-[0_0px_20px_#3c7fed90]
`;

const Btn = tw.button`
py-1 
w-full 
h-auto 
text-start
hover:cursor-pointer hover:bg-slate-200
pl-4
`;

export default User;
