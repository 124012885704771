import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import theme from "../../theme.js";

export default function RowRadioButtonsGroup(props) {
  let { value, handleChange, label_1, label_2 } = props;

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="list"
          control={
            <Radio
              sx={{
                color: theme.colors.primary,
                "&.Mui-checked": {
                  color: theme.colors.primary,
                },
              }}
            />
          }
          label={label_1}
        />
        <FormControlLabel
          value="grid"
          control={
            <Radio
              sx={{
                color: theme.colors.primary,
                "&.Mui-checked": {
                  color: theme.colors.primary,
                },
              }}
            />
          }
          label={label_2}
        />
      </RadioGroup>
    </FormControl>
  );
}
