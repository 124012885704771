import { GOLF_ACITIVITY, USER_FEED } from "@utils/config";
import { httpsAPICall } from "../apis";

export const golfAcitivityApi = (data) => {
  const response = httpsAPICall(
    `${GOLF_ACITIVITY}?start_date=${data.startDate}&end_date=${data.endDate}&time_zone=${data.timeZone}`,
    // `${GOLF_ACITIVITY}?start_date=2019-01-01&end_date=2025-10-06&time_zone=Asia/Kolkata`,
    data,
    "get"
  );
  return response;
};

export const userFeedApi = (data) => {
  const response = httpsAPICall(`${USER_FEED}`, data, "get");
  return response;
};
