import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import theme from "../../theme";

const TabLists = (props) => {
  let {
    selected_Tab,
    change_Tab,
    Tab_1,
    Tab_2,
    Tab_3,
    label_1,
    label_2,
    label_3,
  } = props;

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={selected_Tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={change_Tab}
            aria-label="lab API tabs example"
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.colors.primary,
              },
            }}
          >
            <Tab
              label={label_1}
              value="1"
              sx={{
                fontWeight: selected_Tab === "1" ? "bold" : "normal",
                fontFamily: "Source Sans, sans-serif, DM Sans",
                textTransform: "capitalize",
                "&.Mui-selected": {
                  color: "black",
                  fontWeight: "bold",
                },
              }}
            />
            <Tab
              label={label_2}
              value="2"
              sx={{
                fontWeight: selected_Tab === "2" ? "bold" : "normal",
                fontFamily: "Source Sans, sans-serif, DM Sans",
                textTransform: "capitalize",
                "&.Mui-selected": {
                  color: "black",
                  fontWeight: "bold",
                },
              }}
            />
            {label_3 && (
              <Tab
                label={label_3}
                value="3"
                sx={{
                  fontWeight: selected_Tab === "3" ? "bold" : "normal",
                  fontFamily: "Source Sans, sans-serif, DM Sans",
                  textTransform: "capitalize",
                  "&.Mui-selected": {
                    color: "black",
                    fontWeight: "bold",
                  },
                }}
              />
            )}
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          {Tab_1()}
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          {Tab_2()}
        </TabPanel>
        {label_3 && (
          <TabPanel value="3" sx={{ p: 0 }}>
            {Tab_3()}
          </TabPanel>
        )}
      </TabContext>
    </Box>
  );
};

export default TabLists;
