import { NEWS_FEED, NEWS_FEED_LIKE, NEWS_FEED_DISLIKE } from "@utils/config";
import { httpsAPICall } from "../apis";

export const newsFeedApi = (data) => {
  const response = httpsAPICall(
    `${NEWS_FEED}?type=${data.type}&page_size=${data.pageSize}&page_no=${data.pageNo}&club_id=${data.clubId}`,
    data,
    "get"
  );
  return response;
};

export const newsFeedLikeApi = (data) => {
  const response = httpsAPICall(`${NEWS_FEED_LIKE}`, data, "post");
  return response;
};

export const newsFeedDislikeApi = (data) => {
  const response = httpsAPICall(`${NEWS_FEED_DISLIKE}`, data, "post");
  return response;
};
