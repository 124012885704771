import React from "react";
import { Skeleton, Typography } from "@mui/material";

const SkeletonLoading = (props) => {
  return (
    <div
      className={`${
        props.isGrid ? "w-[48%]" : "w-[100%]"
      } rounded-md`}
    >
      <Typography
        variant="h1"
        width="100%"
        sx={{ fontSize: "5rem", position: "relative" }}
      >
        <Skeleton
          animation="wave"
          sx={{
            height: 250,
            borderRadius: 2,
            bgcolor: "rgba(160, 160, 160, 0.3)",
            ":after": {
              background:
                "linear-gradient(90deg, rgba(255, 255, 255, 0.0), rgba(255, 2555, 255, 1), rgba(255, 255, 255, 0.0));",
            },
          }}
        />
      </Typography>
    </div>
  );
};

export default SkeletonLoading;
