import homeReducer from "@redux/home/slice";
import settingsReducer from "@redux/settings/slice";
import authReducer from "@redux/authentication/slice";
import newsReducer from "@redux/news/slice";
import userReducer from "@redux/user/slice";
import paymentReducer from "@redux/payment/slice";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  authReducer,
  homeReducer,
  settingsReducer,
  newsReducer,
  userReducer,
  paymentReducer,
});

export default rootReducer;
