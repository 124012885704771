import React from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import Dialog from "@mui/material/Dialog";
import { Zoom } from "@mui/material";
import logoIcon from "@assets/images/logo2.png";
import { Text, Img, CustomInput, MuiButton, Link } from "@components";

function LoginModal(props) {
  let {
    openModal,
    changeName,
    name,
    changeSurname,
    surname,
    changeEmail,
    email,
    changePassword,
    password,
    showPassword,
    handleShowPassword,
    btnLoading,
    nameError,
    surnameError,
    emailError,
    validEmailError,
    passwordError,
    login,
    isRegister,
    switchModal,
  } = props;
  const { t } = useTranslation("common");

  return (
    <Dialog
      open={openModal}
      TransitionComponent={Zoom}
      PaperProps={{
        sx: {
          margin: "0%",
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        },
      }}
    >
      <div className="w-full h-full">
        <Container>
          <div className="w-[78px] h-[70px]">
            <Img src={logoIcon} />
          </div>
          <Text className="font-bold text-[20px] text-center mt-4 tracking-widest">
            {isRegister ? "Register" : t("home.login")}
          </Text>

          {isRegister ? (
            <div className="w-full pt-4">
              <CustomInput
                change_Value={changeName}
                value={name}
                placeholder={"Enter your name"}
                label={"Name"}
                error={nameError}
                errorMessage={nameError ? "Enter your name" : ""}
              />
            </div>
          ) : (
            <></>
          )}
          {isRegister ? (
            <div className="w-full pt-4">
              <CustomInput
                change_Value={changeSurname}
                value={surname}
                placeholder={"Enter your surname"}
                label={"Surname"}
                error={surnameError}
                errorMessage={surnameError ? "Enter your surname" : ""}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="w-full pt-4">
            <CustomInput
              change_Value={changeEmail}
              value={email}
              placeholder={t("home.enter_Email")}
              label={t("home.email")}
              error={emailError || validEmailError}
              errorMessage={
                emailError
                  ? t("home.enter_EmailAddress")
                  : validEmailError
                  ? t("home.enter_ValidEmail")
                  : ""
              }
            />
          </div>
          <div className="w-full pt-4">
            <CustomInput
              change_Value={changePassword}
              value={password}
              placeholder={t("home.enter_Password")}
              label={t("home.password")}
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
              error={passwordError}
              errorMessage={passwordError ? t("home.enter_Password") : ""}
              onKeyDown={(e) => e.key === "Enter" && login(e)}
            />
          </div>
          <div className="w-full h-[56px] mt-10">
            <MuiButton
              onClick={login}
              loading={btnLoading}
              height={"50px"}
              type="button"
            >
              {" "}
              {isRegister ? "Register" : t("home.login")}{" "}
            </MuiButton>
          </div>
          <div className="w-full h-auto flex flex-row items-center justify-center">
            <Text className="text-sm leading-normal">
              {isRegister ? "Already Register?" : "Not a member?"}
            </Text>
            <Link onClick={switchModal}>
              <p className="text-[#7D8AF3] text-[14px] capitalize">
                {isRegister ? "Login" : "Register"}
              </p>
            </Link>
          </div>
        </Container>
      </div>
    </Dialog>
  );
}

function CustomModal(props) {
  let { openModal, closeModal } = props;
  const { t } = useTranslation("common");

  return (
    <Dialog
      open={openModal}
      // onClose={closeModal}
      TransitionComponent={Zoom}
      PaperProps={{
        sx: {
          margin: "0%",
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        },
      }}
    >
      <div className="w-full h-full">
        <Container2>
          <div className="w-[78px] h-[70px]">
            <Img src={logoIcon} />
          </div>
          <Text>{"Club id not found. Please add club-id in url"}</Text>
        </Container2>
      </div>
    </Dialog>
  );
}

const Container = tw.div`
lg:w-[540px] md2:w-[500px] md:w-[440px] sm2:w-[360px] sm1:w-[330px] w-[300px] 
h-auto
rounded-md 
bg-white 
flex flex-col items-center justify-start 
gap-1 
lg:p-8 p-4
max-h-[700px]
overflow-x-hidden
overflow-y-scroll
custom-scroll
`;

const Container2 = tw.div`
w-[400px] 
h-auto
rounded-md 
bg-white 
flex flex-col items-center justify-start 
gap-5 
p-5
max-h-[700px]
overflow-x-hidden
overflow-y-scroll
custom-scroll
`;

export { LoginModal, CustomModal };
