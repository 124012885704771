import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  NEWS_FEED,
  NEWS_FEED_UPDATE,
  NEWS_FEED_LIKE,
  NEWS_FEED_DISLIKE,
} from "./types";
import {
  newsFeedApi,
  newsFeedLikeApi,
  newsFeedDislikeApi,
} from "../../services/news";

export const getNewsFeedData = createAsyncThunk(NEWS_FEED, async (data) => {
  try {
    const response = await newsFeedApi(data);
    return response;
  } catch (err) {
    return err;
  }
});

export const updateNewsFeedData = createAsyncThunk(
  NEWS_FEED_UPDATE,
  async (data) => {
    try {
      const response = await newsFeedApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const NewsFeedLikes = createAsyncThunk(NEWS_FEED_LIKE, async (data) => {
  try {
    const response = await newsFeedLikeApi(data);
    return response;
  } catch (err) {
    return err;
  }
});

export const NewsFeedDislikes = createAsyncThunk(
  NEWS_FEED_DISLIKE,
  async (data) => {
    try {
      const response = await newsFeedDislikeApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);
