import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN_USER, REGISTER_USER } from "./types";
import { loginUserApi, registerUserAPi } from "../../services/auth";

export const loginUser = createAsyncThunk(LOGIN_USER, async (data) => {
  try {
    const response = await loginUserApi(data);
    return response;
  } catch (err) {
    return err;
  }
});

export const registerUser = createAsyncThunk(REGISTER_USER, async (data) => {
  try {
    const response = await registerUserAPi(data);
    return response;
  } catch (err) {
    return err;
  }
});
